<script setup>


import {computed, ref, watch} from "vue";
import DefaultFloatingInput from "@/InertiaComponents/DefaultFloatingInput.vue";

const emit = defineEmits(['change'])

const props = defineProps({
    brandId: {
        type: Number,
        default: 0
    },
    itemFamilyId: {
        type: Number,
        default: 0
    },
    hasError: {
        type: Boolean,
        required: false,
        default: false
    },
    errorText: {
        type: String,
        required: false,
        default: ''
    },
    maxResultListHeight: {
        type: String,
        required: false,
        default: null
    }
})

const axiosCancelToken = ref(() => {})
const searchInput = ref('')
const searchIsLoading = ref(false)
const searchEntries = ref({})
const searchEntriesVisible = ref(false)


watch(searchInput, async (newVal, oldVal) => {
    search()
});

const search = () => {
    if (searchInput.value.length < 3) return
    searchIsLoading.value = true
    axiosCancelToken.value()

    axios
        .get('/api/items/search', {
            cancelToken: new axios.CancelToken((c) => (axiosCancelToken.value = c)),
            params: { term: searchInput.value, filterBrand: props.brandId, filterItemFamily: props.itemFamilyId }
        })
        .then(response => {
            searchEntries.value = response.data;
        })
        .catch(e => {
            axios.isCancel(e);
        })
        .finally(() => (searchIsLoading.value = false))
}

const select = (entry) => {
    searchEntriesVisible.value = false
    emit('change', entry)
}

const maxResultListHeightStyle = computed(() => {
    if (props.maxResultListHeight) {
        return 'overflow-y: scroll; max-height: ' + props.maxResultListHeight
    }
    return ''
})

const hideEntries = () => {
    searchEntriesVisible.value = false
}
</script>
<template>

    <div class="row">
        <div class="col position-relative" v-click-outside="hideEntries">
            <default-floating-input
                label-text="Produkt"
                input-type="text"
                v-model="searchInput"
                @focus="searchEntriesVisible = true"
                @click="searchEntriesVisible = true"
                autocomplete="off"
                :has-error="hasError"
                :error-text="errorText"
            />

            <span class="loading form-control-feedback" v-show="searchIsLoading"></span>
            <div class="autocomplete-results" v-if="searchEntries.length && searchEntriesVisible">
                <div class="list-group" :style="maxResultListHeightStyle">
                    <div class="list-group-item" v-for="entry in searchEntries" @mousedown.prevent @click="select(entry)">
                        <div class="row">
                            <div class="col-2">
                                <template v-if="entry.images.length">
                                    <img :src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="img-fluid">
                                </template>
                                <template v-else>
                                    <img src="/storage/img/general/brokenimage.png" alt="" class="img-fluid">
                                </template>
                            </div>
                            <div class="col-10 justify-content-center align-self-center">
                                {{ entry.brand.name }} {{ entry.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


